import { ADDONS_IDS } from '@constants/addonsIds'
import {
  fullAgencyEventTrackerPrices,
  fullBusinessEventTrackerPrices
} from '@constants/addonsPrices'
import { ADDONS_UUIDS } from '@constants/addonsUuids'
import EventTracker from '@images/addons/icons/EventTracker.svg'
import EventTrackerMostPopularImage from '@images/addons/images/eventTrackerMostPopular.svg'
import {
  FEATURE,
  FOURTEEN_DAYS_FREE_TRIAL,
  MOST_POPULAR,
  SEVEN_DAYS_FREE_TRIAL
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { EventTrackerScheduleDemoButton } from '@pages/AddonsStore/routes/Addons/components/Buttons/SecondaryButton'
import {
  BUYABLE,
  BuyableAddonTemplate,
  MostPopularAddonTemplate
} from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getEventTrackerAddon: (
  t: TFunction<'translation', undefined>,
  isAgency: boolean
) => BuyableAddonTemplate = (t, isAgency) => ({
  type: BUYABLE,
  name: t('word.event.tracker'),
  title: t('word.event.tracker'),
  tileDescription: <Trans i18nKey='addons.store.event.tracker.description' />,
  badges: [MOST_POPULAR, FEATURE, SEVEN_DAYS_FREE_TRIAL],
  imageSrc: EventTracker,
  id: ADDONS_IDS.EVENT_TRACKER,
  uuid: ADDONS_UUIDS.EVENT_TRACKER,
  pageDescription: t('addons.store.event.tracker.page.description'),
  overview: getOverview(t),
  features: getFeatures(),
  price: isAgency
    ? fullAgencyEventTrackerPrices
    : fullBusinessEventTrackerPrices,
  pricing: getPricing(t, isAgency),
  useCases: getUseCases(t),
  faq: getFAQ(t),
  featureFlag: 'EVENT_TRACKER',
  secondaryButton: EventTrackerScheduleDemoButton
})

export const getEventTrackerMostPopularAddon: (
  t: TFunction<'translation', undefined>
) => MostPopularAddonTemplate = t => ({
  id: ADDONS_IDS.EVENT_TRACKER,
  title: t('word.event.tracker'),
  tileDescription: t('addons.store.event.tracker.description'),
  badges: [MOST_POPULAR, FOURTEEN_DAYS_FREE_TRIAL],
  mostPopularImageSrc: EventTrackerMostPopularImage,
  featureFlag: 'EVENT_TRACKER',
  uuid: ADDONS_UUIDS.EVENT_TRACKER
})
